import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import dispositivo1 from "../../../assets/img/dispositivos/dispositivo1.jpg";
import dispositivo2 from "../../../assets/img/dispositivos/dispositivo2.jpg";
import dispositivo3 from "../../../assets/img/dispositivos/dispositivo3.jpg";
import dispositivo4 from "../../../assets/img/dispositivos/dispositivo4.jpg";
import dispositivo5 from "../../../assets/img/dispositivos/dispositivo5.jpg";
import dispositivo6 from "../../../assets/img/dispositivos/dispositivo6.jpg";
import dispositivo7 from "../../../assets/img/dispositivos/dispositivo7.jpg";
import dispositivo8 from "../../../assets/img/dispositivos/dispositivo8.jpg";
import dispositivo9 from "../../../assets/img/dispositivos/dispositivo9.jpg";
import dispositivo10 from "../../../assets/img/dispositivos/dispositivo10.jpg";
import dispositivo11 from "../../../assets/img/dispositivos/dispositivo11.jpg";
import dispositivo12 from "../../../assets/img/dispositivos/dispositivo12.jpg";
import dispositivo13 from "../../../assets/img/dispositivos/dispositivo13.jpg";
import dispositivo14 from "../../../assets/img/dispositivos/dispositivo14.jpg";
import dispositivo15 from "../../../assets/img/dispositivos/dispositivo15.jpg";
import dispositivo16 from "../../../assets/img/dispositivos/dispositivo16.jpg";
import "./Carousel.css";

export function CarouselC() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="carousel" id="productos">
      <div className="container mb-4">
        <div className="row">
          <div className="col-12">
            <div className="carousel-bx wow zoomIn">
              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
              >
                <div
                  style={{ borderRadius: "35px" }}
                  className="item bg-white  py-3 mx-3 my-2"
                >
                  <div
                    style={{ width: "fit-content" }}
                    className=" container d-flex justify-content-center"
                  >
                    <img
                      style={{ borderRadius: "30px" }}
                      className="img-fluid"
                      src={dispositivo1}
                      alt="dispositivo1"
                    />
                  </div>
                  <div className="mx-4 py-2">
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuota inicial : $70
                    </p>
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuotas: 16
                    </p>
                  </div>
                </div>
                <div
                  style={{ borderRadius: "35px" }}
                  className="item bg-white  py-3 mx-3 my-2"
                >
                  <div
                    style={{ width: "fit-content" }}
                    className=" container d-flex justify-content-center"
                  >
                    {" "}
                    <img
                      style={{ borderRadius: "30px" }}
                      className="img-fluid"
                      src={dispositivo2}
                      alt="dispositivo2"
                    />
                  </div>
                  <div className="mx-4 py-2">
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuota inicial : $70
                    </p>
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuotas: 16
                    </p>
                  </div>
                </div>
                <div
                  style={{ borderRadius: "35px" }}
                  className="item bg-white  py-3 mx-3 my-2"
                >
                  <div
                    style={{ width: "fit-content" }}
                    className=" container d-flex justify-content-center"
                  >
                    {" "}
                    <img
                      style={{ borderRadius: "30px" }}
                      className="img-fluid"
                      src={dispositivo3}
                      alt="dispositivo3"
                    />
                  </div>
                  <div className="mx-4 py-2">
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuota inicial : $70
                    </p>
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuotas: 16
                    </p>
                  </div>
                </div>
                <div
                  style={{ borderRadius: "35px" }}
                  className="item bg-white  py-3 mx-3 my-2"
                >
                  <div
                    style={{ width: "fit-content" }}
                    className=" container d-flex justify-content-center"
                  >
                    {" "}
                    <img
                      style={{ borderRadius: "30px" }}
                      className="img-fluid"
                      src={dispositivo4}
                      alt="dispositivo4"
                    />
                  </div>
                  <div className="mx-4 py-2">
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuota inicial : $70
                    </p>
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuotas: 16
                    </p>
                  </div>
                </div>
                <div
                  style={{ borderRadius: "35px" }}
                  className="item bg-white  py-3 mx-3 my-2"
                >
                  <div
                    style={{ width: "fit-content" }}
                    className=" container d-flex justify-content-center"
                  >
                    {" "}
                    <img
                      style={{ borderRadius: "30px" }}
                      className="img-fluid"
                      src={dispositivo5}
                      alt="dispositivo5"
                    />
                  </div>
                  <div className="mx-4 py-2">
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuota inicial : $70
                    </p>
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuotas: 16
                    </p>
                  </div>
                </div>
                <div
                  style={{ borderRadius: "35px" }}
                  className="item bg-white  py-3 mx-3 my-2"
                >
                  <div
                    style={{ width: "fit-content" }}
                    className=" container d-flex justify-content-center"
                  >
                    <img
                      style={{ borderRadius: "30px" }}
                      className="img-fluid"
                      src={dispositivo6}
                      alt="dispositivo6"
                    />
                  </div>
                  <div className="mx-4 py-2">
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuota inicial : $70
                    </p>
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuotas: 16
                    </p>
                  </div>
                </div>
                <div
                  style={{ borderRadius: "35px" }}
                  className="item bg-white  py-3 mx-3 my-2"
                >
                  <div
                    style={{ width: "fit-content" }}
                    className=" container d-flex justify-content-center"
                  >
                    {" "}
                    <img
                      style={{ borderRadius: "30px" }}
                      className="img-fluid"
                      src={dispositivo7}
                      alt="dispositivo7"
                    />
                  </div>
                  <div className="mx-4 py-2">
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuota inicial : $70
                    </p>
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuotas: 16
                    </p>
                  </div>
                </div>
                <div
                  style={{ borderRadius: "35px" }}
                  className="item bg-white  py-3 mx-3 my-2"
                >
                  <div
                    style={{ width: "fit-content" }}
                    className=" container d-flex justify-content-center"
                  >
                    {" "}
                    <img
                      style={{ borderRadius: "30px" }}
                      className="img-fluid"
                      src={dispositivo8}
                      alt="dispositivo8"
                    />
                  </div>
                  <div className="mx-4 py-2">
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuota inicial : $70
                    </p>
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuotas: 16
                    </p>
                  </div>
                </div>
                <div
                  style={{ borderRadius: "35px" }}
                  className="item bg-white  py-3 mx-3 my-2"
                >
                  <div
                    style={{ width: "fit-content" }}
                    className=" container d-flex justify-content-center"
                  >
                    {" "}
                    <img
                      style={{ borderRadius: "30px" }}
                      className="img-fluid"
                      src={dispositivo9}
                      alt="dispositivo8"
                    />
                  </div>
                  <div className="mx-4 py-2">
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuota inicial : $70
                    </p>
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuotas: 16
                    </p>
                  </div>
                </div>
                <div
                  style={{ borderRadius: "35px" }}
                  className="item bg-white  py-3 mx-3 my-2"
                >
                  <div
                    style={{ width: "fit-content" }}
                    className=" container d-flex justify-content-center"
                  >
                    {" "}
                    <img
                      style={{ borderRadius: "30px" }}
                      className="img-fluid"
                      src={dispositivo10}
                      alt="dispositivo8"
                    />
                  </div>
                  <div className="mx-4 py-2">
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuota inicial : $70
                    </p>
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuotas: 16
                    </p>
                  </div>
                </div>
                <div
                  style={{ borderRadius: "35px" }}
                  className="item bg-white  py-3 mx-3 my-2"
                >
                  <div
                    style={{ width: "fit-content" }}
                    className=" container d-flex justify-content-center"
                  >
                    {" "}
                    <img
                      style={{ borderRadius: "30px" }}
                      className="img-fluid"
                      src={dispositivo11}
                      alt="dispositivo8"
                    />
                  </div>
                  <div className="mx-4 py-2">
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuota inicial : $70
                    </p>
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuotas: 16
                    </p>
                  </div>
                </div>
                <div
                  style={{ borderRadius: "35px" }}
                  className="item bg-white  py-3 mx-3 my-2"
                >
                  <div
                    style={{ width: "fit-content" }}
                    className=" container d-flex justify-content-center"
                  >
                    {" "}
                    <img
                      style={{ borderRadius: "30px" }}
                      className="img-fluid"
                      src={dispositivo12}
                      alt="dispositivo8"
                    />
                  </div>
                  <div className="mx-4 py-2">
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuota inicial : $70
                    </p>
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuotas: 16
                    </p>
                  </div>
                </div>
                <div
                  style={{ borderRadius: "35px" }}
                  className="item bg-white  py-3 mx-3 my-2"
                >
                  <div
                    style={{ width: "fit-content" }}
                    className=" container d-flex justify-content-center"
                  >
                    {" "}
                    <img
                      style={{ borderRadius: "30px" }}
                      className="img-fluid"
                      src={dispositivo14}
                      alt="dispositivo8"
                    />
                  </div>
                  <div className="mx-4 py-2">
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuota inicial : $70
                    </p>
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuotas: 16
                    </p>
                  </div>
                </div>
                <div
                  style={{ borderRadius: "35px" }}
                  className="item bg-white  py-3 mx-3 my-2"
                >
                  <div
                    style={{ width: "fit-content" }}
                    className=" container d-flex justify-content-center"
                  >
                    {" "}
                    <img
                      style={{ borderRadius: "30px" }}
                      className="img-fluid"
                      src={dispositivo13}
                      alt="dispositivo8"
                    />
                  </div>
                  <div className="mx-4 py-2">
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuota inicial : $70
                    </p>
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuotas: 16
                    </p>
                  </div>
                </div>
                <div
                  style={{ borderRadius: "35px" }}
                  className="item bg-white  py-3 mx-3 my-2"
                >
                  <div
                    style={{ width: "fit-content" }}
                    className=" container d-flex justify-content-center"
                  >
                    {" "}
                    <img
                      style={{ borderRadius: "30px" }}
                      className="img-fluid"
                      src={dispositivo15}
                      alt="dispositivo8"
                    />
                  </div>
                  <div className="mx-4 py-2">
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuota inicial : $70
                    </p>
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuotas: 16
                    </p>
                  </div>
                </div>
                <div
                  style={{ borderRadius: "35px" }}
                  className="item bg-white  py-3 mx-3 my-2"
                >
                  <div
                    style={{ width: "fit-content" }}
                    className=" container d-flex justify-content-center"
                  >
                    {" "}
                    <img
                      style={{ borderRadius: "30px" }}
                      className="img-fluid"
                      src={dispositivo16}
                      alt="dispositivo8"
                    />
                  </div>
                  <div className="mx-4 py-2">
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuota inicial : $70
                    </p>
                    <p style={{ color: "#2F2C57" }} className=" fs-6">
                      Cuotas: 16
                    </p>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
