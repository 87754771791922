import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./enrolamiento.css";
import { useState, useEffect } from "react";
import plus from "../../../assets/img/plus.png";
import circle1 from "../../../assets/img/banner/circle1.jpg";
import circle2 from "../../../assets/img/banner/circle2.jpg";

export function Enrolamiento() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [smallScreen, setSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth < 800) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, [screenWidth]);

  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#2F2C57",
        height: "50rem",
        padding: "50px",
        marginTop: "70px",
        paddingTop: "100px",
      }}
    >
      <img
        src={circle1}
        alt="Circle 1"
        style={{
          position: "absolute",
          top: 0,
          right: -1,
          width: "25rem",
          height: "25rem",
          zIndex: 0,
        }}
      />
      <img
        src={circle2}
        alt="Circle 2"
        style={{
          position: "absolute",
          bottom: 0,
          left: -1,
          width: "25rem",
          height: "25rem",
          zIndex: 0,
        }}
      />
      <div
        style={{ maxWidth: "85rem", width: "90%" }}
        className={`${
          smallScreen ? "flex-column" : "flex-row"
        }  d-flex justify-content-center align-items-center mx-auto `}
      >
        <div
          style={{
            width: smallScreen ? "90%" : "50%",
            margin: "30px",
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <div className="d-flex justify-content-center align-items-start flex-column">
            <h3
              style={{ zIndex: 2, color: "white" }}
              className="fs-1 col-md-10 col-sm-12 title-info-home mb-4"
            >
              Proceso de Enrolamiento
            </h3>
            <p
              style={{ opacity: "80%", maxWidth: "35rem", color: "white" }}
              className="fs-5 mb-4"
            >
              Aquí tendrás el paso a paso de lo que debes hacer para enrolar
              nuevos equipos y convertirte así en uno de los ganadores de
              nuestras comisiones.
            </p>
          </div>
        </div>
        <span
          style={{
            display: "inline-block",
            width: smallScreen ? "10rem" : "1px",
            height: smallScreen ? "1px" : "5rem",
            backgroundColor: "white",
            margin: smallScreen ? "20px 0" : "0 20px",
          }}
        ></span>
        <div
          style={{
            width: smallScreen ? "90%" : "50%",
            margin: "30px",
            zIndex: 1,
          }}
          className="my-3 d-flex justify-content-center align-items-center"
        >
          <div className="d-flex justify-content-center align-items-start flex-column">
            <h3
              style={{ opacity: "80%", color: "white" }}
              className="fs-5 col-md-12 col-sm-12 mb-4"
            >
              Algunos de nuestros datos actuales:
            </h3>
            <div className="d-flex flex-row gap-5">
              <div>
                <h1 className="text-white d-flex justify-content-start flex-row align-items-center">
                  5 <img src={plus} />
                </h1>
                <p
                  style={{ opacity: "80%", maxWidth: "6rem", color: "white" }}
                  className="fs-5 mb-4"
                >
                  Años en el mercado
                </p>
              </div>
              <div>
                <h1 className="text-white d-flex justify-content-start flex-row align-items-center">
                  30 <img src={plus} />
                </h1>
                <p
                  style={{ opacity: "80%", maxWidth: "6rem", color: "white" }}
                  className="fs-5 mb-4"
                >
                  Procesos terminados
                </p>
              </div>
              <div>
                <h1 className="text-white d-flex justify-content-start flex-row align-items-center">
                  500 <img src={plus} />
                </h1>
                <p
                  style={{ opacity: "80%", maxWidth: "6rem", color: "white" }}
                  className="fs-5 mb-4"
                >
                  Clientes actuales
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
